<template>
  <component :is="componentType" :to="to" class="breadcrumb" data-cy="base-breadcrumb" @click="$emit('click')">
    <span class="breadcrumb-icon"><img src="@/assets/icons/back-breadcrumb.svg"></span>
    <span class="breadcrumb-text">{{ text }}</span>
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: true
    }
  },
  emits: ['click'],
  computed: {
    componentType () {
      return this.to.length ? 'router-link' : 'div'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";
@import "@/residency/views/home/dashboard/shared/styles/dashboard-variables.scss";

.breadcrumb {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  max-width: fit-content;
}

.breadcrumb-text {
  font-size: 15px;
  line-height: 23px;
  font-family: "Open Sans Semi-Bold", sans-serif;
  color: $neutral-typography-medium;
  margin-left: 15px;

  @media (min-width: $mobile) and (max-width: $tablet - 1) {
    font-family: "Open Sans";
  }
}

.breadcrumb-icon {
  display: flex;
  margin-top: 3px;
  align-items: center;
  justify-content: center;
}
</style>
