import { workshopApi } from '@/workshop/workshop-api'
import { DEFAULT_OPTIONS } from '@/consts/filter-default-option'

const getDefaultState = () => {
  return {
    pastWorkshops: [],
    pastWorkshopsTotalCount: 0,
    upcomingWorkshops: []
  }
}

const state = getDefaultState()

const getters = {
  pastWorkshops (state, getters) {
    return state.pastWorkshops
      .filter(workshop => filterWorkshops(workshop, getters['workshopTableFilter/filterMenu']))
  },
  upcomingWorkshops (state, getters) {
    return state.upcomingWorkshops
      .filter(workshop => filterWorkshops(workshop, getters['workshopTableFilter/filterMenu']))
  },
  facilitatorsForPastWorkshops: state => {
    return [...new Set(state.pastWorkshops.reduce((accumulator, workshop) => {
      return accumulator.concat(workshop.facilitators)
    }, []))]
  },
  facilitatorsForUpcomingWorkshops: state => {
    return [...new Set(state.upcomingWorkshops.reduce((accumulator, workshop) => {
      return accumulator.concat(workshop.facilitators)
    }, []))]
  },
  pastWorkshopTotalCount: state => state.pastWorkshopsTotalCount,
  upcomingWorkshopTotalCount: state => state.upcomingWorkshops.length
}

const actions = {
  async fetchPastWorkshops ({ commit }) {
    const res = await workshopApi.get('/workshop/past')
    commit('SET_PAST_WORKSHOPS', res.data)
  },
  async fetchPastWorkshopsTotalCount ({ commit }) {
    const res = await workshopApi.get('/workshop/search/totalCountPastWorkshops')
    commit('SET_PAST_WORKSHOPS_TOTAL_COUNT', res.data)
  },
  async fetchUpcomingWorkshops ({ commit }) {
    const res = await workshopApi.get('/workshop/upcoming')
    commit('SET_UPCOMING_WORKSHOPS', res.data)
  }
}

const mutations = {
  SET_PAST_WORKSHOPS (state, workshops) {
    state.pastWorkshops = workshops
  },
  SET_PAST_WORKSHOPS_TOTAL_COUNT (state, count) {
    state.pastWorkshopsTotalCount = count
  },
  SET_UPCOMING_WORKSHOPS (state, workshops) {
    state.upcomingWorkshops = workshops
  }
}

function filterWorkshops (workshop, filterMenuOptions) {
  for (const [key, value] of Object.entries(filterMenuOptions)) {
    if (value !== DEFAULT_OPTIONS.ALL && !filterFields(workshop, key, value)) {
      return false
    }
  }
  return true
}

function filterFields (workshop, key, value) {
  if (key === 'procedure') {
    return workshop.procedure === value
  } else if (key === 'facilitator') {
    return workshop.facilitators.includes(value)
  }
  return false
}

export default {
  state,
  getters,
  actions,
  mutations
}
