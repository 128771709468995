import BaseForm from '@/components/inputs/BaseForm'
import BaseInput from '@/components/inputs/BaseInput'
import BaseInputSelect from '@/components/inputs/BaseInputSelect'
import BaseInputDatalist from '@/components/inputs/BaseInputDatalist'
import BaseInputDate from '@/components/inputs/BaseInputDate'
import BaseInputCheckbox from '@/components/inputs/BaseInputCheckbox'
import BaseButton from '@/components/buttons/BaseButton'
import BaseButtonIcon from '@/components/buttons/BaseButtonIcon'
import BaseTable from '@/components/table/BaseTable'
import BaseVideoPlayer from '@/components/video/BaseVideoPlayer'
import BaseButtonSubmit from '@/components/buttons/BaseButtonSubmit'
import BaseInputTextArea from '@/components/inputs/BaseInputTextArea'
import BaseInputSearchBar from '@/components/inputs/BaseInputSearchBar'
import BaseChipGroup from '@/components/chips/BaseChipGroup'
import BaseChip from '@/components/chips/BaseChip'
import BaseRadioGroup from '@/components/inputs/BaseRadioGroup'
import BaseInputRadio from '@/components/inputs/BaseInputRadio'
import BaseBreadcrumb from '@/components/BaseBreadcrumb'

// Register global components
export default function registerGlobalComponents (app) {
  app.component('BaseForm', BaseForm)
  app.component('BaseInput', BaseInput)
  app.component('BaseInputDate', BaseInputDate)
  app.component('BaseInputSelect', BaseInputSelect)
  app.component('BaseInputDatalist', BaseInputDatalist)
  app.component('BaseInputCheckbox', BaseInputCheckbox)
  app.component('BaseButton', BaseButton)
  app.component('BaseButtonIcon', BaseButtonIcon)
  app.component('BaseTable', BaseTable)
  app.component('BaseVideoPlayer', BaseVideoPlayer)
  app.component('BaseButtonSubmit', BaseButtonSubmit)
  app.component('BaseInputTextArea', BaseInputTextArea)
  app.component('BaseInputSearchBar', BaseInputSearchBar)
  app.component('BaseChipGroup', BaseChipGroup)
  app.component('BaseRadioGroup', BaseRadioGroup)
  app.component('BaseInputRadio', BaseInputRadio)
  app.component('BaseChip', BaseChip)
  app.component('BaseBreadcrumb', BaseBreadcrumb)
}
