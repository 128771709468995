<template>
  <div v-show="visible" :class="tooltipStyle" :style="bounds" ref="tooltip" id="simseitooltip">
    <span>{{ content }}</span>
  </div>
</template>

<script>
export default {
  name: 'SimseiTooltip',
  props: {
    content: {
      type: String,
      required: true
    },
    targetBounds: {
      type: Object,
      required: true
    },
    side: {
      type: String,
      required: true,
      validator: (pos) => {
        return ['top', 'top-start', 'top-end',
          'right', 'right-start', 'right-end',
          'left', 'left-start', 'left-end',
          'bottom', 'bottom-start', 'bottom-end'].indexOf(pos) !== -1
      }
    },
    // Offset in pixels away from the element
    offset: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      left: 0,
      top: 0,
      maxWidth: 0,
      maxHeight: 0,
      tooltipPos: 'top'
    }
  },
  computed: {
    bounds () {
      const bounds = { left: `${this.left}px`, top: `${this.top}px` }

      if (this.maxWidth > 0) {
        bounds.maxWidth = `${this.maxWidth}px`
      }

      if (this.maxHeight > 0) {
        bounds.maxHeight = `${this.maxHeight}px`
      }
      return bounds
    },
    tooltipStyle () {
      return ['tooltip', this.tooltipPos]
    }

  },
  mounted () {
    this.calcBounds(this.side)
  },
  beforeUpdate () {
    this.calcBounds(this.tooltipPos)
  },
  methods: {
    calcBounds (side) {
      // store the arrow position here for CSS in case the side changes from the prop because it
      // gets recalculated due to not fitting on the page
      this.tooltipPos = side

      const bounds = this.$refs.tooltip.getBoundingClientRect()
      switch (side) {
        case 'top':
          this.top = this.targetBounds.top - bounds.height - this.offset
          this.left = this.targetBounds.left - ((bounds.width / 2) - (this.targetBounds.width / 2))
          break
        case 'top-start':
          this.top = this.targetBounds.top - bounds.height - this.offset
          this.left = this.targetBounds.left - (bounds.width - this.offset) * 0.08 // based on #simseitooltip.top-start::after
          break
        case 'top-end':
          this.top = this.targetBounds.top - bounds.height - this.offset
          this.left = this.targetBounds.right - bounds.width + this.offset
          break
        case 'right':
          this.top = this.targetBounds.top - ((bounds.height / 2) - (this.targetBounds.height / 2))
          this.left = this.targetBounds.left + this.targetBounds.width + this.offset
          break
        case 'right-start':
          this.top = this.targetBounds.top - this.offset
          this.left = this.targetBounds.left + this.targetBounds.width + this.offset
          break
        case 'right-end':
          this.top = this.targetBounds.bottom - bounds.height + this.offset
          this.left = this.targetBounds.left + this.targetBounds.width + this.offset
          break
        case 'bottom':
          this.top = this.targetBounds.top + this.targetBounds.height + this.offset
          this.left = this.targetBounds.left - ((bounds.width / 2) - (this.targetBounds.width / 2))
          break
        case 'bottom-start':
          this.top = this.targetBounds.top + this.targetBounds.height + this.offset
          this.left = this.targetBounds.left - (this.offset / 2)
          break
        case 'bottom-end':
          this.top = this.targetBounds.top + this.targetBounds.height + this.offset
          this.left = this.targetBounds.right - bounds.width + this.offset
          break
        case 'left':
          this.top = this.targetBounds.top - ((bounds.height / 2) - (this.targetBounds.height / 2))
          this.left = this.targetBounds.left - bounds.width - this.offset
          if (this.left + bounds.width > this.targetBounds.left) {
            this.maxWidth = this.targetBounds.left
          }
          break
        case 'left-start':
          this.top = this.targetBounds.top - this.offset
          this.left = this.targetBounds.left - bounds.width - this.offset
          if (this.left + bounds.width > this.targetBounds.left) {
            this.maxWidth = this.targetBounds.left
          }
          break
        case 'left-end':
          this.top = this.targetBounds.bottom - bounds.height + this.offset
          this.left = this.targetBounds.left - bounds.width - this.offset
          if (this.left + bounds.width > this.targetBounds.left) {
            this.maxWidth = this.targetBounds.left
          }
          break
        default:
          throw new Error('Unexpected "side" argument received in SimseiTooltip.vue')
      }

      // Prevent the tooltip from going off screen to the left or off the top
      this.left = Math.max(0, this.left)
      this.top = Math.max(0, this.top)

      // If the tooltip can't fit without going off screen, then shrink it
      if (side === 'right' && this.left + bounds.width > window.innerWidth) {
        this.calcBounds('top-end')
      } else if (side === 'left' && this.left + bounds.width > this.targetBounds.left) {
        this.calcBounds('top-start')
      }
    }
  }

}
</script>

<style lang="scss">
$tooltip-bg: rgba(60, 60, 60, 0.95);

@mixin arrow {
  content: "";
  position: absolute;
  border-width: 6px;
  border-style: solid;
}

#simseitooltip.tooltip {
  background-color: $tooltip-bg;
  font-size: 14px;
  color: #FFFFFF;
  z-index: 99999;
  position: fixed;
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 1px rgba(black, 0.25);
  max-width: 400px;
  pointer-events: none;
}

#simseitooltip.top::after {
  @include arrow;

  top: 100%;
  left: 50%;
  border-color: $tooltip-bg transparent transparent transparent;
  transform: translate(-50%);
}

#simseitooltip.top-start::after {
  @include arrow;

  top: 100%;
  left: 8%;
  border-color: $tooltip-bg transparent transparent transparent;
}

#simseitooltip.top-end::after {
  @include arrow;

  top: 100%;
  right: 15px;
  border-color: $tooltip-bg transparent transparent transparent;
}

#simseitooltip.bottom::after {
  @include arrow;

  top: 0%;
  left: 50%;
  border-color: transparent transparent $tooltip-bg transparent;
  transform: translate(-50%, -100%);
}

#simseitooltip.bottom-start::after {
  @include arrow;

  top: 0%;
  left: 0%;
  border-color: transparent transparent $tooltip-bg transparent;
  transform: translate(50%, -100%);
}

#simseitooltip.bottom-end::after {
  @include arrow;

  top: 0%;
  right: 17px;
  border-color: transparent transparent $tooltip-bg transparent;
  transform: translate(0, -100%);
}

#simseitooltip.left::after {
  @include arrow;

  top: 50%;
  left: 100%;
  border-color: transparent transparent transparent $tooltip-bg;
  transform: translate(0, -50%);
}

#simseitooltip.left-start::after {
  @include arrow;

  top: 20%;
  left: 100%;
  border-color: transparent transparent transparent $tooltip-bg;
  transform: translate(0, -25%);
}

#simseitooltip.left-end::after {
  @include arrow;

  top: 75%;
  left: 100%;
  border-color: transparent transparent transparent $tooltip-bg;
  transform: translate(0, -50%);
}

#simseitooltip.right::after {
  @include arrow;

  top: 50%;
  left: -12px;
  border-color: transparent $tooltip-bg transparent transparent;
  transform: translate(0, -50%);
}

#simseitooltip.right-start::after {
  @include arrow;

  top: 20%;
  left: -20px;
  border-color: transparent $tooltip-bg transparent transparent;
  transform: translate(0, -25%);
}

#simseitooltip.right-end::after {
  @include arrow;

  top: 100%;
  left: -20px;
  border-color: transparent $tooltip-bg transparent transparent;
  transform: translate(0, -100%);
}
</style>
