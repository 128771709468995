import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import tableFilter from '@/store/modules/table-filter'
import auth from './modules/auth'
import layout from './modules/layout'
import workshopForm from './modules/workshop-form'
import workshopTableFilter from '@/workshop/store/filter/workshop-table-filter'
import workshops from '@/workshop/store/modules/workshops'
import workshopCourseMgmtModule from '@/workshop/store/admin/workshop-course-mgmt'
import workshopAttendeeMgmt from './admin/workshop-attendee-mgmt'
import adminUser from './modules/admin-user'
import procedure from './modules/workshop-procedure'
import interactionAnalytics from './modules/interaction-analytics'
import toastNotification from '@/store/notifications/toastNotification'

const persistedStateOptions = {
  reducer: state => ({
    auth: state.auth,
    procedure: state.procedure
  }),
  storage: window.sessionStorage
}

export default createStore({
  modules: {
    auth,
    layout,
    tableFilter,
    workshopForm,
    workshopTableFilter,
    workshops,
    workshopCourseMgmtModule,
    workshopAttendeeMgmt,
    adminUser,
    procedure,
    interactionAnalytics,
    toastNotification
  },
  plugins: [createPersistedState(persistedStateOptions)]
})
