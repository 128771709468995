
const state = {
  layout: 'standard-layout'
}

const getters = {
  layout (state) {
    return state.layout
  }
}

const mutations = {
  SET_LAYOUT (state, payload) {
    state.layout = payload
  }
}

export default {
  state,
  getters,
  mutations
}
