/**
 * @param {Date} date date to convert to MM/DD/YYYY
 * @returns String in MM/DD/YYYY date format
 */
export function toDDMMYYYY (date) {
  const year = date.getUTCFullYear()
  const month = (1 + date.getUTCMonth()).toString().padStart(2, '0')
  const day = date.getUTCDate().toString().padStart(2, '0')

  return month + '/' + day + '/' + year
}

/**
 * @param dateObject object with date information
 * @return { time: '02:00 AM', date: '2020-01-01' } of date object
 */
export function parseDateAndTime (dateObject) {
  if (!dateObject) return
  const month = ('0' + (dateObject.getMonth() + 1)).slice(-2)
  const day = ('0' + dateObject.getDate()).slice(-2)
  const date = `${dateObject.getFullYear()}-${month}-${day}`
  let hour = dateObject.getHours()
  const timeOfDay = hour >= 12 ? 'PM' : 'AM'
  hour = hour % 12
  if (hour === 0) hour = 12
  hour = ('0' + hour).slice(-2)
  const minute = ('0' + dateObject.getMinutes()).slice(-2)
  const time = `${hour}:${minute} ${timeOfDay}`
  return { date, time }
}
