import { workshopApi } from '@/workshop/workshop-api.js'

const selectionModeEnum = {
  emailSelection: 'EMAIL_SELECTION',
  registrationSelection: 'REGISTRATION_SELECTION'
}

const getDefaultState = () => {
  return {
    workshopId: null,
    selectionMode: null,
    selectedUsers: [],
    activeAttendees: [],
    inactiveAttendees: []
  }
}

const state = getDefaultState()

const getters = {
  manageAttendeesWorkshopId (state) {
    return state.workshopId
  },
  isEmailSelectionMode (state) {
    return state.selectionMode === selectionModeEnum.emailSelection
  },
  isRegistrationSelectionMode (state) {
    return state.selectionMode === selectionModeEnum.registrationSelection
  },
  selectedUsers (state) {
    return state.selectedUsers
  },
  isSelecting (state) {
    return state.selectionMode !== null
  },
  activeAttendees (state) {
    return state.activeAttendees
  },
  inactiveAttendees (state) {
    return state.inactiveAttendees
  }
}

const actions = {
  setEmailSelectionMode ({ commit }) {
    commit('RESET_USERS_SELECTED')
    commit('SET_SELECTION_MODE', selectionModeEnum.emailSelection)
  },
  setRegistrationSelectionMode ({ commit }) {
    commit('RESET_USERS_SELECTED')
    commit('SET_SELECTION_MODE', selectionModeEnum.registrationSelection)
  },
  exitSelectionMode ({ commit }) {
    commit('RESET_USERS_SELECTED')
    commit('SET_SELECTION_MODE', null)
  },
  addUser ({ commit }, user) {
    commit('ADD_SELECTED_USER', user)
  },
  removeUser ({ state, commit }, user) {
    commit('SET_USERS_SELECTED', state.selectedUsers.filter(selectedUser => selectedUser.email !== user.email))
  },
  clearSelectedUsers ({ commit }) {
    commit('RESET_USERS_SELECTED')
  },
  selectAllUsers ({ commit }, users) {
    commit('SET_USERS_SELECTED', users)
  },
  async fetchActiveWorkshopAttendees ({ getters, commit }) {
    const res = await workshopApi.get(`/attendee/active/${getters.manageAttendeesWorkshopId}`)
    commit('SET_ACTIVE_ATTENDEES', res.data)
  },
  async fetchInactiveWorkshopAttendees ({ getters, commit }) {
    const res = await workshopApi.get(`/attendee/inactive/${getters.manageAttendeesWorkshopId}`)
    commit('SET_INACTIVE_ATTENDEES', res.data)
  },
  setAttendeeIsActive ({ commit }, payload) {
    return workshopApi.patch(`/attendee/set-is-active/${payload.attendee.id}?isActive=${payload.isActive}`)
  },
  submitNewWorkshopAttendee ({ getters }, attendee) {
    return workshopApi.post(`/attendee/add-new-attendee/${getters.manageAttendeesWorkshopId}`, attendee)
  },
  submitExistingWorkshopAttendee ({ getters }, attendee) {
    return workshopApi.post(`/attendee/add-existing-attendee/${getters.manageAttendeesWorkshopId}`, attendee)
  }
}

const mutations = {
  SET_MANAGE_ATTENDEE_WORKSHOP_ID (state, workshopId) {
    state.workshopId = workshopId
  },
  SET_SELECTION_MODE (state, selectionMode) {
    state.selectionMode = selectionMode
  },
  RESET_USERS_SELECTED (state) {
    state.selectedUsers = []
  },
  SET_USERS_SELECTED (state, users) {
    state.selectedUsers = users
  },
  SET_ACTIVE_ATTENDEES (state, activeAttendees) {
    state.activeAttendees = activeAttendees
  },
  SET_INACTIVE_ATTENDEES (state, inactiveAttendees) {
    state.inactiveAttendees = inactiveAttendees
  },
  ADD_SELECTED_USER (state, user) {
    state.selectedUsers.push(user)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
