<template>
  <div class="chip-group">
    <button
      v-if="canScrollLeft && !useScrollbar"
      class="scroll-cell"
      @click="scrollLeft"
    >
      <img
        src="@/assets/icons/right-arrow.svg"
        class="scroll-btn rotate"
      />
    </button>
    <div ref="chipWrapper" class="chip-list-wrapper">
      <ol ref="chipContainer" class="chip-list" :style="chipGroupStyle">
        <slot></slot>
      </ol>
    </div>
    <button
      v-if="canScrollRight & !useScrollbar"
      class="scroll-cell"
      @click="scrollRight"
    >
      <img
        src="@/assets/icons/right-arrow.svg"
        class="scroll-btn"
      />
    </button>
  </div>
</template>

<script>
import { nextTick, computed } from 'vue'

export default {
  provide () {
    return {
      chipGroupValue: computed(() => this.modelValue),
      setSelectedChip: this.setValue
    }
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: false
    },
    useScrollbar: {
      type: Boolean,
      default: false
    },
    scrollbarSpacing: {
      type: String,
      default: '0'
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      scrollValue: 0,
      maxScrollRight: 0,
      scrollStep: 0
    }
  },
  computed: {
    canScrollRight () {
      return this.scrollValue < this.maxScrollRight && !this.useScrollbar
    },
    canScrollLeft () {
      return this.scrollValue > 0 && !this.useScrollbar
    },
    chipGroupStyle () {
      return {
        'padding-bottom': `${this.scrollbarSpacing}`,
        'overflow': this.useScrollbar && !this.inline ? 'scroll' : 'show',
        'display:': this.inline ? 'flex' : 'block'
      }
    }
  },
  mounted () {
    // This delay is needed because it may take time for
    // the dom inside the <slot /> to populate. Without
    // the elements, it can't determin the scroll functionality
    nextTick(this.calcMaxScroll)
  },
  updated () {
    this.calcMaxScroll()
  },
  methods: {
    scrollRight () {
      this.scrollValue += this.scrollStep
      this.$refs.chipContainer.style.transform = `translateX(-${this.scrollValue}px)`
    },
    scrollLeft () {
      this.scrollValue = Math.max(0, this.scrollValue - this.scrollStep)
      this.$refs.chipContainer.style.transform = `translateX(-${this.scrollValue}px)`
    },
    calcMaxScroll () {
      const wrapper = this.$refs.chipWrapper
      const el = this.$refs.chipContainer
      this.maxScrollRight = el.scrollWidth - wrapper.clientWidth
      this.scrollStep = (wrapper.clientWidth / 2)
    },
    setValue (chipValue) {
      this.$emit('update:modelValue', chipValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixins.scss";
@include scrollbar-redesign;

::-webkit-scrollbar {
  height: 3px;
}

.chip-list-wrapper {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.chip-group {
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin: 4px;
}

.chip-list {
  display: flex;
  list-style: none;
  width: 100%;
  padding-left: 0px;
  overflow: visible;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

:deep(.chip-list li) {
  display: inline-block;
  margin: 8px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 32px;
  }
}

.chips {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
}

.scroll-cell {
  height: 100%;
  min-width: 30px;
  font-family: "Open Sans Semi-Bold";
  cursor: pointer;
  text-align: center;
  padding: 6px;
}

.scroll-btn {
  padding: 8px;
}

.rotate {
  transform: rotate(180deg);
}

.placeholder {
  min-width: 30px;
}

</style>
