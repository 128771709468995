<template>
  <div class="table-container">
    <div v-if="isLoading" class="spinner-container">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <table v-else class="base-table">
      <slot name="thead" :fields="visibleFields">
        <!-- Slotted default content header-->
        <BaseTableHeader
          :fields="visibleFields"
          :sortDesc="sortDesc"
          :sortedField="sortedField"
          @toggleSort="toggleSortSelection"
        />
      </slot>
      <slot name="tbody" :items="processedItems">
        <BaseTableBody
          :fields="visibleFields"
          :processedItems="processedItems"
          :tbodyTrClass="tbodyTrClass"
          :highlightOnHover="highlightOnHover"
          :showExpand="showExpand"
          :expandedRows="expandedRows"
          :rowDataCy="rowDataCy"
          @click:row="$emit('click:row', $event)"
        >
          <template #no-data-placeholder>
            <slot name="no-data-placeholder"></slot>
          </template>

          <template
            v-for="field in visibleFields"
            v-slot:[field.value]="{ item, toggleDetails }"
          >
            <slot :name="field.value" :item="item" :toggleDetails="toggleDetails">
              <div :key="field.value" >
                {{ field.formatter ? field.formatter(item[field.value]) : item[field.value] }}
              </div>
            </slot>
          </template>
          <template #expanded-slot="{ item }">
            <slot name="expanded-slot" :item="item">
            </slot>
          </template>
          <template #details="{ item }">
            <slot name="details" :item="item"></slot>
          </template>
        </BaseTableBody>
      </slot>
    </table>
  </div>
</template>

<script>
import BaseTableHeader from './BaseTableHeader'
import BaseTableBody from './BaseTableBody'

export default {
  components: {
    BaseTableHeader,
    BaseTableBody
  },
  props: {
    /**
     * Ex; fields: [
     *  {
     *    value: String 'value associated with items' (required),
     *    text: String 'text header displayed' (required),
     *    thClass: String 'header style classes' (optional),
     *    tdClass: String 'table body style classes for header column' (optional),
     *    formatter: Function 'function for formatting item data, ex (data) => return format(data)' (optional),
     *    filterByFormatted: Boolean 'Should items be filtered by formatted or unformatted version' (optional)
     *    sortable: Boolean 'should be sortable' (optional)
     *    hidden: Boolean 'should be hidden' (optional) - if true, will not be shown in table but can be used in text search
    *    }
     * ]
     */
    fields: {
      type: Array,
      required: true
    },
    /**
     * Ex: items: [
     *  {
     *    key: String (unique, required),
     *    'optional include any values used in field.value'
     *  }
     * ]
     */
    items: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * @input item: Object, the row item being filtered
     * @input search: String, the search string
     * @output Boolean, based on if the item should be shown
     */
    customFilter: {
      type: Function,
      required: false
    },
    /**
     * @input item: Object, the row item
     * @output String, styling classes for tr in tbody element; ex 'tr tr-bold'
     */
    tbodyTrClass: {
      type: Function,
      required: false,
      default: (item) => ''
    },
    initialSortBy: {
      type: String,
      required: false,
      default: ''
    },
    initialSortDesc: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    highlightOnHover: {
      type: Boolean,
      required: false,
      default: false
    },
    showExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    expandedRows: {
      type: Array,
      required: false
    },
    rowDataCy: {
      type: String,
      required: false
    }
  },
  emits: ['click:row'],
  data () {
    return {
      sortedField: '',
      sortDesc: false
    }
  },
  computed: {
    processedItems () {
      let items = [...this.items]
      // Apply filter to items if any
      items = this.filterItems(items)
      items = this.sortItems(items)
      return items
    },
    sortFieldObj () {
      return this.fields.find(field => field.value === this.sortedField)
    },
    visibleFields () {
      return this.fields.filter(field => !field.hidden)
    }
  },
  created () {
    this.sortedField = this.initialSortBy
    this.sortDesc = this.initialSortDesc
  },
  methods: {
    filterItems (items) {
      if (!this.search) return items
      return items.filter(item => {
        // Check if custom filter was passed in
        if (this.customFilter) {
          return this.customFilter(item, this.search)
        }
        return this.defaultFilter(item)
      })
    },
    sortItems (items) {
      if (!this.sortFieldObj) return items
      const sortOrder = this.sortDesc ? -1 : 1
      const sortValue = this.sortFieldObj.value
      return items.sort((a, b) => {
        if (a[sortValue] === null || a[sortValue] === undefined) return sortOrder
        if (b[sortValue] === null || a[sortValue] === undefined) return -1 * sortOrder
        if (this.sortFieldObj.customSort) {
          return sortOrder * this.sortFieldObj.customSort(a[sortValue], b[sortValue])
        } else {
          if (typeof a[sortValue] === 'string') {
            return a[sortValue].toLowerCase() > b[sortValue].toLowerCase() ? sortOrder : sortOrder * -1
          } else {
            return a[sortValue] > b[sortValue] ? sortOrder : sortOrder * -1
          }
        }
      })
    },
    toggleSortSelection (field) {
      if (!field.sortable) return ''
      const newSortBy = field.value
      // If the same field was selected flip sort order, else sort by new field
      if (newSortBy === this.sortedField) {
        this.sortDesc = !this.sortDesc
      } else {
        this.sortedField = newSortBy
      }
    },
    defaultFilter (item) {
      if (!this.search) return true
      if (typeof item !== 'object') {
        this.$log.error('Type error, items passed must be an array of objects')
        return false
      }
      let found = false
      for (let i = 0; i < this.fields.length; i++) {
        const field = this.fields[i]
        const valueKey = field.value
        if (item[valueKey]) {
          // If formatter exists first format
          const value = field.formatter && field.filterByFormatted ? field.formatter(item[valueKey]) : item[valueKey]
          if (typeof value === 'string' && value.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())) {
            found = true
            break
          }
        }
      }
      // Search was not found in any field rows
      return found
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/components/table/table-mixin.scss";

.table-container {
  @include table-container;
}

.spinner-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  min-height: $spinner-container-height - 10px;
}

/* stylelint-disable selector-class-pattern */
:deep(.v-progress-circular__overlay) {
  stroke: $primary-digital-teal-default;
}
/* stylelint-enable selector-class-pattern */

.base-table {
  width: 100%;
  border-collapse: collapse;
}
</style>
