<template>
  <div :class="{'remove-base-icons': this.leftIcon || this.rightIcon}">
    <input
      :value="modelValue"
      :id="id"
      :type="type"
      :autofocus="autofocus"
      :disabled="disabled"
      :required="required"
      :style="inputStyle"
      :placeholder="placeholder"
      :name="name"
      :min="min"
      :max="max"
      :data-cy="dataCy"
      :spellcheck="spellcheck"
      :autocapitalize="autocapitalize"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      :readonly="readonly"
      data-testid="base-input"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown="handleKeydown"
    >
    <slot />
    <InputErrorBucket v-if="hasValidationRules" :message="errorMessage" />
    <CharactersRemainingLabel
      v-if="maxlength"
      :modelValue="modelValue"
      :maxlength="maxlength"
      :triggerValue="remainingCharsThreshold"
    />
  </div>
</template>

<script>
import CharactersRemainingLabel from '@/components/inputs/components/CharactersRemainingLabel.vue'
import InputErrorBucket from './components/InputErrorBucket.vue'
import { useInputValidation } from './composables/useInputValidation.js'

export default {
  components: {
    CharactersRemainingLabel,
    InputErrorBucket
  },
  props: {
    id: {
      type: String,
      requird: false
    },
    rules: {
      type: Function,
      required: false
    },
    modelValue: {
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    leftIcon: {
      type: String,
      required: false
    },
    rightIcon: {
      type: String,
      required: false
    },
    width: {
      type: [String, Number],
      required: false
    },
    hideDetails: {
      type: [String, Boolean],
      required: false,
      default: 'auto'
    },
    placeholder: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    min: {
      type: [Number, String],
      required: false
    },
    max: {
      type: [Number, String],
      required: false
    },
    formatter: {
      type: Function,
      required: false
    },
    filled: {
      type: Boolean,
      required: false
    },
    blurHandler: {
      type: Function,
      required: false
    },
    focusHandler: {
      type: Function,
      required: false
    },
    keydownHandler: {
      type: Function,
      required: false
    },
    dataCy: {
      type: String,
      required: false
    },
    spellcheck: {
      type: Boolean,
      required: false,
      default: true
    },
    autocapitalize: {
      type: String,
      required: false,
      validator: (value) => {
        return ['none', 'off', 'on', 'sentences', 'words', 'characters'].indexOf(value) !== -1
      }
    },
    maxlength: {
      type: Number,
      required: false
    },
    paddingRight: {
      type: [Number, String],
      required: false
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    remainingCharsThreshold: {
      type: Number,
      required: false
    }
  },
  emits: ['focus', 'blur', 'keydown', 'update:modelValue'],
  setup (props) {
    const {
      errorMessage,
      hasValidationRules,
      isValid,
      setAllowValidation,
      validate,
      resetValidation,
      reset
    } = useInputValidation(props)
    return { errorMessage, hasValidationRules, isValid, setAllowValidation, validate, resetValidation, reset }
  },
  computed: {
    inputStyle () {
      let style = ''
      if (this.width) {
        if (isNaN(this.width)) style += `width:${this.width};` // cases such as '3rem', '10%', etc.
        else style += `width:${this.width}px;`
      }

      if (this.leftIcon) {
        style +=
          `background: url(${require(`@/assets/icons/${this.leftIcon}`)}) no-repeat;` +
          'backgroundPosition: left 14px center;' +
          'backgroundSize: 16px;' +
          'paddingLeft: 36px;'
      }
      if (this.rightIcon) {
        style +=
          `background: url(${require(`@/assets/icons/${this.rightIcon}`)}) no-repeat right 14px center / 16px, #FFFFFF;`
      }

      if (this.paddingRight) {
        style += `padding-right: ${this.paddingRight}px;`
      }

      return style
    }
  },
  methods: {
    async handleInput (event) {
      event.target.value = this.formatter ? this.formatter(event.target.value) : event.target.value
      this.$emit('update:modelValue', event.target.value)
      await this.$nextTick()
      this.validate()
    },
    handleBlur (event) {
      event.target.value = this.blurHandler ? this.blurHandler(event.target.value) : event.target.value
      this.$emit('blur', event.target.value)
    },
    handleFocus (event) {
      event.target.value = this.focusHandler ? this.focusHandler(event.target.value) : event.target.value
      this.$emit('focus', event.target.value)
    },
    handleKeydown (event) {
      event.target.value = this.keydownHandler ? this.keydownHandler(event.target.value) : event.target.value
      this.$emit('keydown', event)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/inputs/input.scss";

input {
  @include form-control;
}
</style>
