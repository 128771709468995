<template>
  <div>
    <div class="input-row">
      <input
        type="checkbox"
        :checked="isChecked"
        :id="id"
        :name="name"
        :autofocus="autofocus"
        :disabled="disabled"
        :required="required"
        :value="value"
        class="base-checkbox"
        :class="{ 'base-checkbox-disabled': disabled }"
        :style="{ 'min-height': size, 'min-width': size }"
        :data-cy="dataCy"
        @input="onValueChange"
      >
      <label
        :for="id"
        class="base-checkbox-label"
        :class="{ 'base-checkbox-label-disabled': disabled }"
      >
        <slot></slot>
      </label>
    </div>
    <InputErrorBucket v-if="hasValidationRules" :message="errorMessage" />
  </div>
</template>

<script>
import InputErrorBucket from './components/InputErrorBucket.vue'
import { useInputValidation } from './composables/useInputValidation.js'

export default {
  components: { InputErrorBucket },
  props: {
    id: {
      type: [String, Number],
      requird: false,
      default: 'base-checkbox'
    },
    modelValue: {
      required: false
    },
    value: {
      type: [String, Object],
      required: false
    },
    rules: {
      type: Function,
      required: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: 'base-checkbox'
    },
    size: {
      type: String,
      required: false,
      default: '18px'
    },
    dataCy: {
      type: [String, Number],
      required: false
    }
  },
  emits: ['update:modelValue'],
  setup (props) {
    const {
      errorMessage,
      hasValidationRules,
      isValid,
      setAllowValidation,
      validate,
      resetValidation,
      reset
    } = useInputValidation(props)
    return { errorMessage, hasValidationRules, isValid, setAllowValidation, validate, resetValidation, reset }
  },
  computed: {
    isChecked () {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.find(value => value === this.value)
      } else {
        return this.modelValue
      }
    }
  },
  methods: {
    onValueChange (event) {
      if (Array.isArray(this.modelValue)) {
        if (this.modelValue.find(value => value === this.value)) {
          this.$emit('update:modelValue', this.modelValue.filter(value => value !== this.value))
        } else {
          this.$emit('update:modelValue', [...this.modelValue, this.value])
        }
      } else {
        this.$emit('update:modelValue', event.target.checked)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/inputs/input.scss";

.input-row {
  display: flex;
  align-items: center;
}

.base-checkbox {
  @include base-checkbox;
}

.base-checkbox-label {
  @include base-checkbox-label;
}

.base-checkbox-label-disabled {
  color: $neutral-dark-grey;
}

.base-checkbox-disabled, .base-checkbox-label-disabled {
  cursor: default;
}
</style>
