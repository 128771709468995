<template>
  <div>
    <div class="base-radio-group">
      <slot></slot>
    </div>
    <InputErrorBucket v-if="hasValidationRules" :message="errorMessage" />
  </div>
</template>

<script>
import { randomString } from '@/utils/string-util'
import { computed } from 'vue'
import { useInputValidation } from './composables/useInputValidation'
import InputErrorBucket from './components/InputErrorBucket.vue'

/**
 * BaseRadioGroup provides a grouping mechanism for BaseInputRadio components so that
 * all related radio buttons have the same name and a single v-model
 *
 * <BaseRadioGroup v-model="selectedValue">
 *   <BaseInputRadio label="Option 1" value="1" />
 *   <BaseInputRadio label="Option 2" value="2" />
 * </BaseRadioGroup>
 */
export default {
  components: { InputErrorBucket },
  provide () {
    return {
      radioGroupValue: computed(() => this.modelValue),
      radioGroupName: computed(() => this.groupName),
      isRadioGroupInvalid: computed(() => !this.isValid),
      isRadioGroupDisabled: computed(() => this.disabled),
      setRadioSelected: this.setValue
    }
  },
  props: {
    modelValue: {
      type: [String, Number, Object, Boolean],
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Function,
      required: false
    },
    name: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  setup (props) {
    const {
      errorMessage,
      hasValidationRules,
      isValid,
      setAllowValidation,
      validate,
      resetValidation,
      reset
    } = useInputValidation(props)
    return { errorMessage, hasValidationRules, isValid, setAllowValidation, validate, resetValidation, reset }
  },
  computed: {
    groupName () {
      return this.name || randomString()
    }
  },
  methods: {
    async setValue (value) {
      this.$emit('update:modelValue', value)
      await this.$nextTick()
      this.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.base-radio-group {
  display: flex;
}
</style>
