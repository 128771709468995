/*
 * SimseiTooltip
 * Pass a string:  v-s-tooltip:top="`Simsei Tooltip`"
 * Pass an object: v-s-tooltip="{ content: 'Simsei Tooltip', placement: 'top' }"
 *
 * Placement:
 *   top
 *   top-start
 *   top-end
 *   right
 *   right-start
 *   right-end
 *   bottom
 *   bottom-start
 *   bottom-end
 *   left
 *   left-start
 *   left-end
 *
 * Options through passing an object:
 *   ref: use innerHTML from another element as tooltip content.
 *   visible: if the tooltip should show
 *   content: the content if passing an object to the directive
 *   offset: offset in pixels away from the element that the tooltip is for
 *
 */
import { createApp } from 'vue'
import SimseiTooltip from '@/residency/components/tooltip/SimseiTooltip'

export default (topElementId) => {
  let tooltip = null
  let refNode = null

  const removeTooltip = () => {
    const existingTooltip = document.getElementById('simseitooltip')
    const existingContainer = document.getElementById('simseitooltip-container')
    if (existingTooltip) {
      existingContainer.parentNode.removeChild(existingContainer)
      tooltip.unmount()
      existingTooltip.remove()
    }
  }

  // This object monitors a target element to watch for DOM changes to its children.
  // This will not trigger on the watched node itself.
  const removalObserver = new MutationObserver(e => {
    // We are only interested in changes where nodes are removed
    if (e[0].removedNodes.length === 0) return

    // If a tooltip uses a ref element, the ref node is removed from its original location in the dom when the tooltip
    // is displayed, causing this observer to run.

    // If the removed node is the tooltips ref element, don't remove the tooltip
    for (const removedNode of e[0].removedNodes) {
      // attributes[0].name is the Vue data-v-N element id
      if (removedNode.attributes[0].name === refNode?.attributes[0].name) {
        return
      }
    }

    // Removal of any other child node will cause the tooltip to be removed
    removeTooltip()
  })

  // Destroy tooltip on scroll
  document.addEventListener('scroll', removeTooltip, true)

  // Destroy tooltip on backbutton
  window.addEventListener('popstate', removeTooltip)

  document.addEventListener('click', removeTooltip)

  // Hook into pushState so that we know when vue changes
  // routes and can remove the tooltip
  const lastPushState = history.pushState
  history.pushState = function () {
    lastPushState.apply(history, arguments)
    removeTooltip()
  }

  // This function is to find the 'ref' element for the tooltip
  // content. First it checks the direct children of the element
  // that the tooltip is attached to. Otherwise check the parent
  // element.
  const findRef = (ref, node) => {
    const refElement = node.$refs[ref] || node.$parent.$refs[ref]
    if (!refElement) {
      throw new Error(`Tooltip: Cannot find ref element ${ref}`)
    }

    if (Array.isArray(refElement)) {
      return refElement[0]
    } else {
      return refElement
    }
  }

  const isElement = (value) => {
    return value instanceof window.Element ? true : null
  }

  return {
    mounted (el, binding, vnode) {
      let tooltipContent = ''
      const target = document.getElementById(topElementId)

      if (binding.value === null) {
        throw new Error('Tooltip is missing content')
      } else if (typeof binding.value !== 'object') {
        // This case is called when the value inside of the attribute
        // is a string, which will be used for the content of the tooltip
        // example: v-s-tooltip="This is content"
        tooltipContent = `${binding.value}`
      } else if (typeof binding.value === 'object' && binding.value.ref) {
        // This case is called when the value inside of the attribute
        // is an object and references a different element for the content
        // example: v-s-tooltip="{ ref: 'someOtherElement' }"
        // <div ref="someOtherElement">Tooltip Content</div>
        const ref = findRef(binding.value.ref, binding.instance)
        if (ref) {
          tooltipContent = ref
          refNode = ref
          ref.style.display = 'none'
        } else {
          throw new Error('Invalid tooltip ref')
        }
      } else if (typeof binding.value === 'object' && binding.value.hasOwnProperty('content')) {
        // This case is called when the value inside of the attribute is an object
        // and has a 'content' property.
        // example: v-s-tooltip=" { content: 'This is content' }"
        tooltipContent = `${binding.value.content}`
      } else {
        throw new Error('Invalid tooltip value')
      }

      el.addEventListener('mouseover', (e) => {
        const tooltipContainer = document.createElement('div')
        tooltipContainer.setAttribute('id', 'simseitooltip-container')
        target.appendChild(tooltipContainer)

        const bounds = el.getBoundingClientRect()

        tooltip = createApp({
          name: 'SimseiTooltipInstance',
          extends: SimseiTooltip
        }, {
          content: isElement(tooltipContent) ? '' : tooltipContent,
          isElement: isElement(tooltipContent),
          targetBounds: bounds,
          // Offset is the number of pixels away from the target element
          // that the tooltip is shifted (like margin)
          // Default is 20px to account for the arrow
          offset: binding.value?.offset ? binding.value.offset : 8,
          side: binding.arg || binding.value.placement || 'top',
          visible: binding.value.visible
        })
        tooltip.mount(tooltipContainer)

        if (isElement(tooltipContent)) {
          document.getElementById('simseitooltip').appendChild(tooltipContent).style.display = 'inline'
        }
      })

      el.addEventListener('mouseout', (e) => {
        if (tooltip) {
          const existingTooltip = document.getElementById('simseitooltip')
          const existingContainer = document.getElementById('simseitooltip-container')
          if (existingTooltip) {
            existingContainer.parentNode.removeChild(existingContainer)
            tooltip.unmount()
          }
        }
      })

      // Observe the element that the tooltip is attached to
      // so that the tooltip can be removed if the element was
      // removed
      removalObserver.observe(el, { childList: true })
    }
  }
}
